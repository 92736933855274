import React from 'react'; 
import { StoryblokComponent } from "gatsby-source-storyblok";
import Layout from "../global/layout";
import "./thank-you.scss";
import { getCookie } from "../global/helpers";
import Button from '../components/button/button'
import HTMLHead from '../global/HTMLHead';

const ThankYou = ({ location }) => {  

    const formData = getCookie( location?.state?.data.form_id || '' ); //Get cookie saved on form submit
    const pageContent = location?.state?.data.page_content;     
    
    const downloadCTA = {
        _uid: "download_cta_id",
        Label: "Download ",
        Style: "secondary",
        component: "button",
        content_target: { 
            linktype: "story",
            fieldtype: "multilink", 
            story: { 
                uuid: "download_cta_story_id", 
                url: formData?.last_converting_page || '',  
            }
        },
        position_horizontal: "left", 
    }  

    //If form is gated and content blocks are used
    if ( formData?.gate_form && pageContent[pageContent.length-1]?.content_blocks  ) {
        pageContent[pageContent.length-1].content_blocks.push(downloadCTA);
    }  

    const components = pageContent ? pageContent.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />)) : null

    let pageInfo = { 
        slug : 'thank-you', 
    }

    return (
        <Layout pageInfo={pageInfo}>  
            {
                components
                    ? components
                    : (  
                        <section className="block_standard content_block">
                            <div className="inner">
                                <div className="block-content">
                                    <div className="text left" style={{ 'maxWidth' : '680px' }} > 
                                        <h3>Thank you</h3>
                                        <p>Thanks for submitting your message. A representative will reach out shortly.</p>  
                                    </div>

                                    {formData?.gate_form &&
                                        <Button blok={downloadCTA} />
                                    }

                                </div>
                            </div>
                        </section> 
                    )
            } 
        </Layout>
    )
}

export const Head = ({ data }) => {
    return (
        <>
            <title>Thank You! | Zignal Labs</title>
            <HTMLHead data={data}></HTMLHead>
        </>
    )
}

export default ThankYou
